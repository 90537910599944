/* eslint-disable camelcase */
/* eslint-disable @next/next/no-img-element */

import { Box, Grid, Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import QueryString from 'qs';
import { useSnackbar } from 'notistack';
import { useRouter, usePathname } from 'next/navigation';
import SummaryCard from '@/app/components/cards/summary-card';
import SummaryGraph from '@/app/components/cards/summary-graph';
import { encryptRequest, decryptResponse } from '@/app/utilities/helpers/security';
import { axiosInstance } from '@/app/utilities/helpers/axios';
import { useUserContext } from '@/app/components/contexts/user-context';

function getAppTypeCounts(data, isMain) {
  if (!data) return { Total: [] };
  const appTypes = {}; // Object to hold arrays for each app type
  const totalCounts = Array(data.length).fill(0); // Initialize the Total array with 0s

  const mainAppTypes = ['Android', 'Roku'];
  // Iterate over each day's data to initialize arrays for each app type
  data.forEach(dayData => {
    const { app_types } = dayData;

    // Initialize array for each app_type if it doesn't exist
    Object.keys(app_types).forEach(type => {
      if (!appTypes[type]) {
        appTypes[type] = Array(data.length).fill(0); // Initialize with 0s
      }
    });
  });

  console.log('[app types]', appTypes);

  // Populate each app type's array with counts and compute totals
  data.forEach((dayData, index) => {
    Object.keys(appTypes).forEach(type => {
      const count = dayData.app_types[type] || 0;
      appTypes[type][index] = count;
      totalCounts[index] += count; // Add to total for the day
    });
  });

  // Add the Total array to the result
  appTypes.Total = totalCounts;

  return appTypes;
}

function getUniqueAppTypes(data) {
  if (!data || data.length === 0) return [];

  const specifiedOrder = [
    'Android',
    'Roku',
    'Samsung',
    'LG',
    'iOS',
    'tvOS',
    'windows',
    'zeasn',
    'vidaa',
  ];
  const appTypesSet = new Set();

  // Collect all unique app_types
  data.forEach(dayData => {
    Object.keys(dayData.app_types).forEach(appType => appTypesSet.add(appType));
  });

  // Sort according to the specified order
  return specifiedOrder.filter(appType => appTypesSet.has(appType));
}

export const SummaryBox = ({ period }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [periodData, setPeriodData] = useState({
    devices: {
      total: 0,
      activated: 0,
      activatedLifetime: 0,
    },
    orders: {
      total: 0,
      totalConfirmed: 0,
    },
    credits: {
      purchased_credits: 0,
      used_credit: 0,
    },
  });

  const [weeklyData, setWeeklyData] = useState();

  const router = useRouter();
  const path = usePathname();
  const { user, timezone } = useUserContext();

  const getPeriodData = useCallback(async () => {
    try {
      const body = {
        data: encryptRequest({
          period,
        }),
      };

      const headers = {
        'X-Timezone-Alias': timezone,
      };

      if (user.type === 'admin') {
        headers['X-Platform-Alias'] = user.platform;
      }

      const response = await axiosInstance.post(
        'upvIWElBqiZPaxlBQcbqzlvNptxH6K5w',
        QueryString.stringify(body),
        {
          headers,
          withCredentials: true,
        }
      );

      const responseData = decryptResponse(response.data);

      if (responseData.success) {
        setPeriodData(responseData.data);
      } else {
        enqueueSnackbar(responseData.message, { variant: 'error' });
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.setItem('redirectAfterLogin', path);
        router.push('/login');
        return;
      }
      enqueueSnackbar('Server Error', { variant: 'error' });
    }
  }, [enqueueSnackbar, path, period, router, timezone, user.platform, user.type]);

  const getWeeklyData = useCallback(async () => {
    try {
      const body = {
        data: encryptRequest({}),
      };

      const headers = {
        'X-Timezone-Alias': timezone,
      };

      if (user.type === 'admin') {
        headers['X-Platform-Alias'] = user.platform;
      }

      const response = await axiosInstance.post(
        'cOTnuR6iTzT0m0rrNLkgHS8vOIrvqLyj',
        QueryString.stringify(body),
        {
          headers,
          withCredentials: true,
        }
      );

      const responseData = decryptResponse(response.data);

      if (responseData.success) {
        setWeeklyData(responseData.data);
        // Set page Data
      } else {
        enqueueSnackbar(responseData.message, { variant: 'error' });
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.setItem('redirectAfterLogin', path);
        router.push('/login');
        return;
      }
      enqueueSnackbar('Server Error', { variant: 'error' });
    }
  }, [enqueueSnackbar, path, router, timezone, user.platform, user.type]);

  useEffect(() => {
    getPeriodData();
    getWeeklyData();
  }, [getPeriodData, getWeeklyData]);

  if (Object.keys(user).length === 0) {
    return null;
  }

  return (
    <Box maxWidth="xl">
      <Stack gap={4}>
        <Grid container spacing={4}>
          <Grid xs={12} sm={6} md={3} item>
            {user.type !== 'reseller' ? (
              <SummaryCard
                title="Total Devices"
                total={periodData.devices.total}
                icon={<img alt="icon" src="/assets/icons/glass/ic_glass_bag.png" />}
              />
            ) : (
              <SummaryCard
                title="Activated Devices"
                total={periodData.devices.activated}
                color="info"
                hasTooltip
                tooltip={`Lifetime activated: ${
                  periodData.devices.activatedLifetime
                }, One year activated: ${
                  periodData.devices.activated - periodData.devices.activatedLifetime
                }`}
                icon={<img alt="icon" src="/assets/icons/glass/ic_glass_users.png" />}
              />
            )}
          </Grid>

          <Grid xs={12} sm={6} md={3} item>
            {user.type !== 'reseller' ? (
              <SummaryCard
                title="Activated Devices"
                total={periodData.devices.activated}
                color="info"
                hasTooltip
                tooltip={`Lifetime activated: ${
                  periodData.devices.activatedLifetime
                }, One year activated: ${
                  periodData.devices.activated - periodData.devices.activatedLifetime
                }`}
                icon={<img alt="icon" src="/assets/icons/glass/ic_glass_users.png" />}
              />
            ) : (
              <SummaryCard
                title="Total Credits"
                total={periodData.credits.purchased_credits}
                color="warning"
                icon={<img alt="icon" src="/assets/icons/glass/ic_glass_buy.png" />}
              />
            )}
          </Grid>

          <Grid xs={12} sm={6} md={3} item>
            {user.type !== 'reseller' ? (
              <SummaryCard
                title="Total Orders"
                total={periodData.orders.total}
                color="warning"
                icon={<img alt="icon" src="/assets/icons/glass/ic_glass_buy.png" />}
              />
            ) : (
              <SummaryCard
                title="Used Credits"
                total={periodData.credits.used_credit}
                color="warning"
                icon={<img alt="icon" src="/assets/icons/glass/ic_glass_buy.png" />}
              />
            )}
          </Grid>

          <Grid xs={12} sm={6} md={3} item>
            {user.type !== 'reseller' ? (
              <SummaryCard
                title="Confirmed Orders"
                total={periodData.orders.totalConfirmed}
                color="warning"
                icon={<img alt="icon" src="/assets/icons/glass/ic_glass_message.png" />}
              />
            ) : (
              <SummaryCard
                title="Left credits"
                total={periodData.credits.purchased_credits - periodData.credits.used_credit}
                color="warning"
                icon={<img alt="icon" src="/assets/icons/glass/ic_glass_message.png" />}
              />
            )}
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid xs={12} md={6} lg={6} item>
            <SummaryGraph
              title="Total Devices"
              tooltipUnit="devices"
              chart={{
                labels: weeklyData?.totalDevices.map(i => i.weekday),
                series: [
                  {
                    name: 'Total',
                    type: 'area',
                    fill: 'gradient',
                    data: getAppTypeCounts(weeklyData?.totalDevices).Total,
                  },
                  {
                    name: 'Average',
                    type: 'area',
                    fill: 'gradient',
                    stroke: 1,
                    data: Array(7).fill(
                      Math.round(
                        getAppTypeCounts(weeklyData?.totalDevices).Total.reduce(
                          (sum, num) => sum + num,
                          0
                        ) / 7
                      )
                    ),
                  },
                  ...(getUniqueAppTypes(weeklyData?.totalDevices).map(type => ({
                    name: type,
                    type: 'area',
                    fill: 'gradient',
                    data: getAppTypeCounts(weeklyData?.totalDevices)[type],
                  })) || []),
                ],
              }}
            />
          </Grid>

          <Grid xs={12} md={6} lg={6} item>
            <SummaryGraph
              title="Activated Devices"
              tooltipUnit="devices"
              chart={{
                labels: weeklyData?.activatedDevices.map(i => i.weekday),
                series: [
                  {
                    name: 'Total',
                    type: 'area',
                    fill: 'gradient',
                    data: getAppTypeCounts(weeklyData?.activatedDevices).Total,
                  },
                  {
                    name: 'Average',
                    type: 'area',
                    fill: 'gradient',
                    stroke: 1,
                    data: Array(7).fill(
                      Math.round(
                        getAppTypeCounts(weeklyData?.activatedDevices).Total.reduce(
                          (sum, num) => sum + num,
                          0
                        ) / 7
                      )
                    ),
                  },
                  ...(getUniqueAppTypes(weeklyData?.activatedDevices)?.map(type => ({
                    name: type,
                    type: 'area',
                    fill: 'gradient',
                    data: getAppTypeCounts(weeklyData?.activatedDevices)[type],
                  })) || []),
                ],
              }}
            />
          </Grid>

          <Grid xs={12} md={6} lg={6} item>
            {user.type !== 'reseller' && (
              <SummaryGraph
                title="Total Orders"
                tooltipUnit="orders"
                chart={{
                  labels: weeklyData?.totalOrders.map(i => i.weekday),
                  series: [
                    {
                      name: 'Total',
                      type: 'area',
                      fill: 'gradient',
                      data: getAppTypeCounts(weeklyData?.totalOrders).Total,
                    },
                    {
                      name: 'Average',
                      type: 'area',
                      fill: 'gradient',
                      stroke: 1,
                      data: Array(7).fill(
                        Math.round(
                          getAppTypeCounts(weeklyData?.totalOrders).Total.reduce(
                            (sum, num) => sum + num,
                            0
                          ) / 7
                        )
                      ),
                    },
                    ...(getUniqueAppTypes(weeklyData?.totalOrders)?.map(type => ({
                      name: type,
                      type: 'area',
                      fill: 'gradient',
                      data: getAppTypeCounts(weeklyData?.totalOrders)[type],
                    })) || []),
                  ],
                }}
              />
            )}
          </Grid>

          <Grid xs={12} md={6} lg={6} item>
            {user.type !== 'reseller' && (
              <SummaryGraph
                title="Confirmed Orders"
                tooltipUnit="orders"
                chart={{
                  labels: weeklyData?.confirmedOrders.map(i => i.weekday),
                  series: [
                    {
                      name: 'Total',
                      type: 'area',
                      fill: 'gradient',
                      data: getAppTypeCounts(weeklyData?.confirmedOrders).Total,
                    },
                    {
                      name: 'Average',
                      type: 'area',
                      fill: 'gradient',
                      stroke: 1,
                      data: Array(7).fill(
                        Math.round(
                          getAppTypeCounts(weeklyData?.confirmedOrders).Total.reduce(
                            (sum, num) => sum + num,
                            0
                          ) / 7
                        )
                      ),
                    },
                    user.type === 'super-admin' && {
                      name: 'Main',
                      type: 'area',
                      fill: 'gradient',
                      stroke: 1,
                      data: Array(7).fill(
                        Math.round(
                          getAppTypeCounts(weeklyData?.mainTotal).Total.reduce(
                            (sum, num) => sum + num,
                            0
                          ) / 7
                        )
                      ),
                    },
                    ...(getUniqueAppTypes(weeklyData?.confirmedOrders)?.map(type => ({
                      name: type,
                      type: 'area',
                      fill: 'gradient',
                      data: getAppTypeCounts(weeklyData?.confirmedOrders)[type],
                    })) || []),
                  ].filter(Boolean),
                }}
              />
            )}
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};
