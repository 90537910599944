'use client';

import { forwardRef } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';

import { RouterLink } from '../router-link/router-link';

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.main;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.main;

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 100,
        height: 100,
        display: 'inline-flex',
        paddingTop: 1,
        pl: 2,
        ...sx,
      }}
      {...other}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 1230.92 447.93"
      >
        <defs>
          <style>
            {`
              .cls-1 {
                fill: ${theme.palette.primary.main};
                stroke-width: 0px;
              }
              .cls-2 {
                fill: transparent;
              }
            `}
          </style>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>

          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>

          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>

        <g id="Layer_2">
          <rect width="1230.92" height="447.93" className="cls-2" />
        </g>
        <g id="Layer_1">
          <g>
            <path
              className="cls-1"
              d="M134.14,339.14c-3.22,0-6.44-.8-8.45-2.41-16.89-14.88-35.38-28.15-66.35-28.15-18.9,0-43.03,6.03-43.03,32.17,0,33.37,75.6,47.04,92.48,47.04,77.61,0,98.11-51.47,98.11-76.4,0-90.87-192.21-12.87-192.21-108.57,0-59.51,72.78-88.86,143.55-88.86,31.36,0,57.1,12.47,68.76,36.19,1.21,2.01,1.61,4.42,1.61,6.43,0,13.67-20.11,25.74-29.75,25.74l-2.41-.4c-.4,0-.8-.4-.8-.8s0-.4.4-.8c2.81-3.62,8.44-10.45,8.44-16.09,0-.8,0-1.61-.4-2.41-2.01-5.63-26.54-14.88-60.32-14.88s-92.08,12.47-92.08,54.28c0,67.15,190.6-10.05,190.6,119.83,0,71.58-82.83,96.91-118.22,96.91-39.41,0-121.84-26.13-121.84-75.59,0-38.6,39-53.88,69.96-53.88s57.5,4.83,82.43,37c0,8.04-11.66,13.67-20.51,13.67Z"
            />
            <path
              className="cls-1"
              d="M340.82,261.13c10.86,0,12.87,15.68,13.67,31.36.4,12.87.8,24.93,1.61,30.16,5.23-14.07,19.71-53.08,51.07-53.08,11.66,0,20.51,12.07,21.31,20.91.8,15.68,6.03,120.23-6.03,120.63-21.72,1.2-23.32-32.97-23.73-38.2-.4-6.84-.8-28.15-.8-45.84,0-12.07.4-22.92,1.21-25.73-19.7,8.04-26.94,65.95-34.98,88.87-1.21,3.62-6.44,5.63-10.45,5.63-20.91.8-26.94-89.27-26.94-99.72-19.7,12.87-39.81,26.94-39.41,83.24,0,16.08-.8,29.35-14.07,29.76-6.03.4-10.05-2.01-12.87-11.26-2.81-8.85-4.83-39-4.83-50.67,0-16.49,1.61-34.18,4.42-48.26,3.62-16.08,4.83-28.15,18.9-28.15,11.26,0,12.47,17.29,13.67,23.32,2.41-4.02,25.73-32.97,48.25-32.97Z"
            />
            <path
              className="cls-1"
              d="M514.93,251.08h2.41c5.23,0,10.85,6.83,11.66,12.46,2.01,6.84,9.25,26.54,10.85,37.4,4.83,34.99,8.04,54.69,8.04,85.65,0,14.08,0,17.69-1.21,22.52-1.21.8-2.81,1.21-4.42,1.21-7.64,0-13.67-8.45-18.09-18.9l-1.61-23.32h-12.87c-14.07,0-18.9-.4-22.12-.4-1.21,0-2.01,0-2.41.4-6.03,2.01-8.85,17.69-14.48,32.57l-4.43,11.66c-2.01,2.01-5.63,3.22-9.65,3.22-2.41,0-4.83-.8-8.04-1.21-5.63-6.03-7.64-11.26-7.64-19.7,0-9.65,4.02-21.71,11.26-34.58,0-6.43-3.62-13.27-12.06-14.48l.8-7.64c1.61.4,3.62.4,5.63.4h13.27c6.43-13.67,20.51-42.22,30.16-56.7l19.3-23.73,1.21-4.83c1.21-2.01,2.41-2.01,4.42-2.01ZM507.29,336.73c3.62,0,8.04,0,11.66-.4,0-7.64-.4-25.33-5.63-34.58-5.63,1.61-14.48,24.93-17.29,34.58,3.62.4,7.64.4,11.26.4Z"
            />
            <path
              className="cls-1"
              d="M578.86,406.29c-8.85,0-19.3-7.64-19.3-19.3.4-34.58,4.02-54.28,14.88-87.66,1.61-6.03,9.65-8.85,16.48-8.85,5.63,0,10.45,2.01,10.45,6.84-8.04,33.78-9.65,66.35-12.06,99.32-.8,6.43-5.23,9.65-10.45,9.65ZM570.01,281.24c0-9.65,25.33-19.3,36.19-19.3,48.26,0,76.4,21.31,76.4,60.32,0,13.27-7.24,24.53-17.69,32.57,10.45,11.66,17.69,26.94,26.13,41.41,1.21,2.01,1.61,4.83,1.61,6.84,0,8.04-6.84,14.07-12.07,14.07-1.61,0-2.81-.4-4.02-1.61-1.2-1.61-20.51-28.95-35.79-48.25-7.64,2.01-15.28,3.21-22.52,3.21-12.87,0-21.72-8.84-21.72-20.51,0-7.64,12.47-14.88,22.52-14.88s18.49,2.01,25.73,5.23c5.23-6.43,8.85-13.67,8.85-19.3,0-30.96-34.99-36.19-53.88-36.19-8.04,0-15.28,4.83-21.71,5.23-5.63-2.81-8.04-6.03-8.04-8.85ZM629.92,354.42c-4.83-4.83-6.03-5.63-11.26-5.63-4.83.4-7.64,2.01-7.64,4.42,0,4.02,4.02,5.63,6.84,5.63,3.62,0,6.84-.81,12.06-4.43Z"
            />
            <path
              className="cls-1"
              d="M753.37,444.89c-7.64,0-16.49-8.44-20.11-13.27-6.84-10.45-7.24-28.15-7.24-40.61,0-34.58,6.84-62.73,13.67-84.04-27.35-10.86-41.82-23.72-41.82-39.41,0-5.23,2.01-15.68,8.04-17.69,5.23,0,2.01,10.05,4.83,12.47,13.67,11.26,27.34,14.88,43.83,17.29,7.24-6.84,18.9-24.13,38.6-24.13,10.86,0,27.75,3.22,27.75,32.57,0,22.92-32.57,26.94-46.64,26.94h-2.41c-6.84,25.74-8.04,55.09-8.04,81.63,0,15.68.4,30.96.8,44.23-3.62,3.22-5.23,4.02-11.26,4.02ZM786.74,283.25c6.43,0,18.5-4.83,18.5-10.05,0-3.21-2.41-6.43-6.03-6.43-8.04,0-18.49,5.63-18.9,15.68,2.01.4,4.02.8,6.43.8Z"
            />
            <path
              className="cls-1"
              d="M923.05,385.38c-10.86,8.44-35.39,27.34-61.92,27.34-22.12,0-32.17-16.49-32.17-33.78,0-9.25,5.63-21.71,10.05-34.18-1.21-4.02-3.22-6.43-3.22-10.45,0-3.21,1.21-4.83,2.01-8.04,3.22-1.21,6.84-2.81,10.45-3.22l9.65-22.12c-9.25-4.42-22.92-9.65-22.92-18.09,0-4.42,3.62-5.63,10.45-6.03,16.89-.81,36.59-8.04,69.56-8.04,3.62,0,8.04.4,16.49,2.81,3.62,4.42,12.87,12.87,12.87,19.7,0,4.02-14.08,8.45-21.72,8.85-17.29,1.21-21.31.8-32.17,1.21-2.01,0-4.02,0-6.03.4-1.61,1.61-4.83,6.84-4.83,16.89,0,1.61.81,4.02,3.62,4.02h29.75l5.63.81c2.01,2.81,2.41,8.04,2.41,11.66,0,3.22-.8,9.25-4.42,9.65-7.24.4-25.33,1.21-45.44,1.21-.8,0-1.61,0-2.41,1.61-2.01,8.44-5.63,18.09-9.65,26.94,2.01,0,17.29-4.02,43.03-6.43,4.02-.4,9.25-.8,13.27-.8,4.83,0,10.05,6.83,10.05,12.06,0,2.41-.4,4.83-2.41,6.03Z"
            />
            <path
              className="cls-1"
              d="M971.7,406.29c-8.85,0-19.3-7.64-19.3-19.3.4-34.58,4.02-54.28,14.88-87.66,1.61-6.03,9.65-8.85,16.48-8.85,5.63,0,10.45,2.01,10.45,6.84-8.04,33.78-9.65,66.35-12.06,99.32-.8,6.43-5.23,9.65-10.45,9.65ZM962.85,281.24c0-9.65,25.33-19.3,36.19-19.3,48.26,0,76.4,21.31,76.4,60.32,0,13.27-7.24,24.53-17.69,32.57,10.45,11.66,17.69,26.94,26.13,41.41,1.21,2.01,1.61,4.83,1.61,6.84,0,8.04-6.84,14.07-12.07,14.07-1.61,0-2.81-.4-4.02-1.61-1.2-1.61-20.51-28.95-35.79-48.25-7.64,2.01-15.28,3.21-22.52,3.21-12.87,0-21.72-8.84-21.72-20.51,0-7.64,12.47-14.88,22.52-14.88s18.49,2.01,25.73,5.23c5.23-6.43,8.85-13.67,8.85-19.3,0-30.96-34.99-36.19-53.88-36.19-8.04,0-15.28,4.83-21.71,5.23-5.63-2.81-8.04-6.03-8.04-8.85ZM1022.77,354.42c-4.83-4.83-6.03-5.63-11.26-5.63-4.83.4-7.64,2.01-7.64,4.42,0,4.02,4.02,5.63,6.84,5.63,3.62,0,6.84-.81,12.06-4.43Z"
            />
            <path
              className="cls-1"
              d="M1110.83,305.37c0,.4.4.8.8.8,15.28,5.63,25.33,7.64,41.42,8.44,27.34,2.01,67.95,2.41,67.95,40.21,0,43.43-48.25,45.84-61.12,45.84-34.18,0-64.34-21.72-64.34-38.2,0-19.71,20.51-24.93,36.59-24.93s35.39,4.02,51.87,12.47c.4.4.8.8.8,1.61,0,1.21-.4,2.01-.8,2.41-3.22,3.21-12.87,8.04-22.92,8.04-11.66,0-27.75-9.65-43.43-9.65-5.63,0-12.47,1.21-12.47,10.05,0,10.05,18.09,10.05,32.57,10.05,7.64,0,63.13-6.43,63.13-21.31,0-14.08-42.22-20.11-59.51-20.51-46.24-1.21-52.27-6.03-52.27-22.92,0-37.8,76.4-40.61,80.02-40.61,20.11,0,38.2,3.62,51.47,6.84,2.81.4,4.42,1.61,4.42,6.03-.4,4.83-4.42,7.64-8.85,9.25-3.21.8-10.05,2.01-13.27,2.41-14.07,2.01-55.49,3.62-68.36,6.03-13.27,2.01-18.9,3.62-22.92,6.03-.4.4-.8,1.21-.8,1.61Z"
            />
          </g>
          <path
            className="cls-1"
            d="M1121.05,118.12C865.94-12,551.94-12.02,293.66,109.03c-15.04,6.52-36.17,17.5-50.94,24.86-7.66,4.19-17.36,1.2-21.27-6.63-3.93-7.79-.57-17.35,7.35-20.99,15.57-7.16,37.54-17.58,53.21-23.79C548.07-31.5,867.1-22.59,1121.05,118.12h0Z"
          />
          <g>
            <path
              className="cls-1"
              d="M1104.49,249.74c-1.9.42-4.12-.44-4.89-2.54-.41-1.13-.43-2.28-.22-3.44.46-2.51,1.69-4.64,3.29-6.58.26-.31.54-.6.83-.88-1.09,1.46-2.04,3-2.67,4.72-.56,1.54-.88,3.11-.59,4.75.38,2.21,1.75,3.55,3.96,3.91.1.02.19.03.29.05Z"
            />
            <path
              className="cls-1"
              d="M1220.36,220.04c-1.36,4.86-2.33,7.19-5.83,7.19-4.67,0-8.4-7.02-10.45-10.38-2.61-4.26-5.22-8.52-7.82-12.79-.04-.05-.07-.1-.09-.15-4.89-8.02-9.71-16.08-14.28-24.3-.4-.72-.8-1.44-1.2-2.16-1.36-2.92-2.92-5.06-4.47-6.61-4.28,0-7.97.97-11.28,3.11-2.4,1.56-.53,6,.52,7.79,1.77,3.02,3.14,6.26,4.9,9.29,1.81,3.11,3.75,6.16,5.84,9.09,8.25,11.61,16.86,22.96,25.49,34.29,1.94,2.53,9.72,10.11,11.28,10.11,5.83,0,8.75-6.61,8.75-14.58,0-3.11-.39-6.81-1.36-9.92Z"
            />
            <path
              className="cls-1"
              d="M1056.84,171.43c5.25,0,6.22,7.58,6.61,15.17.2,6.22.39,12.06.78,14.58,2.53-6.81,9.53-25.67,24.69-25.67,5.64,0,9.92,5.83,10.31,10.11.39,7.58,2.92,58.14-2.92,58.33-10.5.58-11.28-15.95-11.47-18.47-.2-3.31-.39-13.61-.39-22.17,0-5.83.19-11.08.58-12.44-9.53,3.89-13.03,31.89-16.92,42.97-.58,1.75-3.11,2.72-5.06,2.72-10.11.39-13.03-43.17-13.03-48.22-9.53,6.22-19.25,13.03-19.05,40.25,0,7.78-.39,14.19-6.81,14.39-2.92.19-4.86-.97-6.22-5.44-1.36-4.28-2.33-18.86-2.33-24.5,0-7.97.78-16.53,2.14-23.33,1.75-7.78,2.33-13.61,9.14-13.61,5.44,0,6.03,8.36,6.61,11.28,1.17-1.94,12.44-15.94,23.33-15.94Z"
            />
            <path
              className="cls-1"
              d="M1141.03,166.57h1.17c2.53,0,5.25,3.3,5.64,6.03.97,3.31,4.47,12.83,5.25,18.08,2.33,16.92,3.89,26.45,3.89,41.42,0,6.81,0,8.56-.58,10.89-.58.39-1.36.58-2.14.58-3.69,0-6.61-4.08-8.75-9.14l-.78-11.28h-6.22c-6.81,0-9.14-.2-10.69-.2-.58,0-.97,0-1.17.2-2.92.97-4.28,8.55-7,15.75l-2.14,5.64c-.97.97-2.72,1.56-4.67,1.56-1.17,0-2.33-.39-3.89-.58-2.72-2.92-3.69-5.44-3.69-9.53,0-4.67,1.94-10.5,5.44-16.72,0-3.11-1.75-6.42-5.83-7l.39-3.69c.78.2,1.75.2,2.72.2h6.42c3.11-6.61,9.92-20.42,14.58-27.42l9.33-11.47.58-2.33c.58-.97,1.17-.97,2.14-.97ZM1137.33,207.99c1.75,0,3.89,0,5.64-.2,0-3.69-.2-12.25-2.72-16.72-2.72.78-7,12.06-8.36,16.72,1.75.2,3.69.2,5.44.2Z"
            />
            <g>
              <path
                className="cls-1"
                d="M1153.92,124.43l8.92-5.87,14.94-11.88-49.79,14.21,40.79,21.32-11.01-13.37s17.85,1.18,33.66,11.61c12.93,8.54,16.45,27.99,12.23,42.04-5.25,17.48-18.28,32.1-32.13,43.45-6.4,5.25-13.24,9.96-20.41,14.1-3.63,2.09-7.34,4.03-11.13,5.81-1.89.89-3.81,1.74-5.74,2.55-1.75.74-7.26,2.64-9.11,3.19,1.84-.45,6.33-1.1,7.57-1.4,3.64-.87,7.19-2.13,10.67-3.51,8.4-3.33,16.27-8.42,23.57-13.67,14.83-10.67,28.23-24.22,36.04-40.94,5.06-10.86,7.25-23.1,3.73-34.77-2.23-7.38-6.68-14.1-12.87-18.74-5.95-4.47-13.33-6.65-20.51-8.2-4.56-.98-9.22-1.83-13.89-2.15-3.01-.2-2.97-.48-1.87.62"
              />
              <path
                className="cls-1"
                d="M1159.12,121.41c1.22-.1,2.55.08,3.77.17,1.58.12,3.18.44,4.74.74,3.14.6,6.25,1.42,9.28,2.43,5.99,2.01,11.71,4.82,16.97,8.31,5.17,3.44,10.29,7.42,13.88,12.56,3.77,5.39,5.81,11.65,6.63,18.14.82,6.5.3,13.12-.83,19.56-.1.6-.21,1.19-.33,1.78-.01.06-.02.12-.04.19-.98,4.96-2.3,9.92-4.13,14.65-.04.12-.3.67-.35.78,0,0-12.77,27.82-49.58,45.45-28.59,14.26-43.64,12.59-52.25,9.94-8.61-2.66-14.3-8.94-14.3-8.94,0,0,12.09,7.19,17.55,7.52,5.03.61,16.11,1.29,30.22-3.82,5.39-1.95,10.77-3.96,15.98-6.37,5.01-2.32,10.02-4.92,14.7-7.88,5.09-3.21,9.65-7.25,13.89-11.51,7.78-7.82,14.18-15.02,20.29-24.19.59-.88,1.76-3.57,2.1-4.41.36-.88.66-1.71.92-2.51.75-2.33,1.4-4.7,1.95-7.08.77-3.35,1.35-6.74,1.69-10.15.4-3.94.5-7.91.17-11.85-.89-10.7-5.51-20.78-14.04-27.58-7.91-6.3-17.78-11.11-27.61-13.48-5.07-1.22-10.3-1.77-15.51-1.45,1.31-.8,2.71-.85,4.23-.98Z"
              />
            </g>
          </g>
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

export default Logo;
