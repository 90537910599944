'use client';

import { Stack } from '@mui/material';
import { useState } from 'react';
import MyBreadcrumbs from '@/app/components/breadcrumbs';
import { DEFAULT_DASHBOARD_PERIODS, paths } from '@/app/utilities/consts';

import { DashBoardTabs } from '@/app/components/dashboard/tabs';
import { SummaryBox } from './summary-box';

const DashBoardView = () => {
  const [currentPeriod, setCurrentPeriod] = useState(DEFAULT_DASHBOARD_PERIODS[0].value);

  return (
    <>
      <Stack
        sx={{
          flexDirection: {
            sm: 'column',
            md: 'row',
          },
          justifyContent: 'space-between',
          mb: { xs: 3, md: 5 },
        }}
      >
        <MyBreadcrumbs
          heading="Dashboard"
          links={[{ name: 'Overview' }, { name: 'Dashboard', href: paths.home }]}
        />
        <DashBoardTabs currentPeriod={currentPeriod} setCurrentPeriod={setCurrentPeriod} />
      </Stack>
      <SummaryBox period={currentPeriod} />
    </>
  );
};

export default DashBoardView;
