import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import Logo from '../../logo';
import { hideScroll } from '@/app/theme/css';

import { NAV } from '@/app/utilities/consts';
import { adminNavData } from './config-navigation';
import NavToggleButton from '../../navigation/vertical/nav-toggle-button';
import NavSectionMini from '../../navigation/mini/nav-section-mini';
import { useUserContext } from '../../contexts/user-context';

export default function NavMini() {
  const { user } = useUserContext();

  const adminNav = adminNavData.map(section => ({
    subheader: section.subheader,
    items: section.items.filter(
      item =>
        user.type === 'super-admin' ||
        (user.type === 'reseller' && item.reseller) ||
        (user.type === 'admin' && item.admin)
    ),
  }));

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_MINI,
          borderRight: theme => `dashed 1px ${theme.palette.divider}`,
          ...hideScroll.x,
        }}
      >
        <Logo sx={{ mx: 'auto', my: 1, ml: -1 }} />

        <NavSectionMini data={adminNav} />
      </Stack>
    </Box>
  );
}
