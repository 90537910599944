import { paths } from '@/app/utilities/consts';
import SvgColor from '../../svg-color';
// ----------------------------------------------------------------------

const icon = name => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  setting: icon('ic_setting'),
  language: icon('ic_language'),
  promotion: icon('ic_label'),
};

export const adminNavData = [
  {
    subheader: 'Overview',
    items: [
      {
        title: 'Dashboard',
        path: paths.home,
        icon: ICONS.dashboard,
        reseller: true,
        admin: true,
      },
    ],
  },
  {
    subheader: 'Management',
    items: [
      {
        title: 'Devices',
        path: paths.devices,
        icon: ICONS.analytics,
        reseller: true,
        admin: true,
      },
      {
        title: 'Orders',
        path: paths.orders,
        icon: ICONS.order,
        reseller: false,
        admin: true,
      },
      {
        title: 'Resellers',
        path: paths.resellers,
        icon: ICONS.external,
        reseller: true,
      },
      {
        title: 'Credits',
        path: paths.credits,
        icon: ICONS.invoice,
        reseller: true,
      },
      {
        title: 'Promotion',
        path: paths.promotion,
        icon: ICONS.promotion,
        reseller: false,
      },
    ],
  },
  {
    subheader: 'Settings',
    items: [
      {
        title: 'App Settings',
        path: paths.appSettings,
        icon: ICONS.setting,
        reseller: false,
      },
      {
        title: 'User Settings',
        path: paths.userSettings,
        icon: ICONS.user,
        reseller: true,
        admin: true,
      },
      {
        title: 'Language Settings',
        path: paths.languageSettings,
        icon: ICONS.language,
        reseller: false,
      },
    ],
  },
];
